import React from 'react';
import Faq from '../Faq/Faq';
import { Trans } from 'gatsby-plugin-react-i18next';
import { FaqType } from '../../types/faq.types';

const FAQS: Array<FaqType> = [
  {
    question: <Trans>Как увеличить объем продаж на маркетплейсах?</Trans>,
    answer: (
      <>
        <p>
          <Trans>
            Вы можете повлиять на объемы продаж, увеличив количество торговых
            предложений и повышая репутацию своих брендов. Но крупные
            маркетплейсы отслеживают учетные записи, принадлежащие одному
            пользователю и незамедлительно блокируют их, лишая продавца
            основного канала продаж и источника постоянного дохода.
          </Trans>
        </p>
        <p>
          <Trans>
            Octo Browser позволяет создавать в товарных агрегаторах
            неограниченное количество аккаунтов и с их помощью увеличить охват
            целевой аудитории и стимулировать продажи.
          </Trans>
        </p>
      </>
    ),
  },
  {
    question: <Trans>Как улучшить репутацию бренда?</Trans>,
    answer: (
      <Trans>
        Для потенциального покупателя отзывы являются основным показателем
        надежности бренда. Но лояльность аудитории могут разрушить несколько
        негативных отзывов. Octo Browser позволяет защитить репутацию бренда с
        помощью мультиаккаунтинга, нивелируя негативные отзывы большим числом
        положительных.
      </Trans>
    ),
  },
  {
    question: <Trans>Преимущества парсинга данных</Trans>,
    answer: (
      <>
        <p>
          <Trans>
            Анализ рынка необходим для поиска категорий товаров с наибольшим
            спросом и возможностью выгодной реализации в том или ином регионе. С
            помощью парсеров это можно сделать автоматически, без потери времени
            на посещение десятков площадок, просмотра каталогов товаров и
            фиксацию их стоимости.
          </Trans>
        </p>
        <p>
          <Trans>
            Octo Browser позволяет осуществлять безопасный доступ парсера к
            заданным площадкам электронной коммерции и получать аналитические
            данные без риска блокировки.
          </Trans>
        </p>
      </>
    ),
  },
  {
    question: <Trans>Глобальный анализ рынка</Trans>,
    answer: (
      <Trans>
        Некоторые локальные маркетплейсы и коммерческие сайты намеренно
        закрывают доступ к своим площадкам пользователям из других стран. Но
        порой для развития бизнеса необходимо отслеживать региональные и мировые
        цены на определённые категории товаров. Кроме этого, доступ к зарубежным
        ресурсам позволяет перенимать эффективные методы увеличения продаж и
        продвижения. Используя Octo Browser и прокси, можно легко обойти эти
        блокировки и осуществить мониторинг интересующих площадок.
      </Trans>
    ),
  },
];

type FaqScrappingProps = {
  title: JSX.Element;
};
const FaqScrapping = ({ title }: FaqScrappingProps): React.ReactElement => {
  return <Faq isWhite faqs={FAQS} title={title} />;
};

export default FaqScrapping;
