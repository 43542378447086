import React from 'react';
import { graphql } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';
import { LanguageMap } from '../../const/language.const';
import Layout from '../../layouts';
import DownBlock from '../../components/DownBlock/DownBlock';
import AppRating from '../../components/AppRating';
import SolutionLayout from '../../components/Solutions/SolutionLayout/SolutionsLayout';
import headSrc from '../../assets/images/solutions/ecommerce/head.png';
import headSrc2x from '../../assets/images/solutions/ecommerce/head_2x.png';
import headRuSrc from '../../assets/images/solutions/ecommerce/head_ru.png';
import headRuSrc2x from '../../assets/images/solutions/ecommerce/head_ru_2x.png';
import Description1 from '../../assets/images/solutions/ecommerce/description_1.svg';
import Description2 from '../../assets/images/solutions/ecommerce/description_2.svg';
import Description3 from '../../assets/images/solutions/ecommerce/description_3.svg';
import Description4 from '../../assets/images/solutions/ecommerce/description_4.svg';
import block1Src from '../../assets/images/solutions/ecommerce/block_1.png';
import block1Src2x from '../../assets/images/solutions/ecommerce/block_1_2x.png';
import block2Src from '../../assets/images/solutions/ecommerce/block_2.png';
import block2Src2x from '../../assets/images/solutions/ecommerce/block_2_2x.png';
import block3Src from '../../assets/images/solutions/ecommerce/block_3.png';
import block3Src2x from '../../assets/images/solutions/ecommerce/block_3_2x.png';
import block4Src from '../../assets/images/solutions/ecommerce/block_4.png';
import block4Src2x from '../../assets/images/solutions/ecommerce/block_4_2x.png';
import block5Src from '../../assets/images/solutions/ecommerce/block_5.png';
import block5Src2x from '../../assets/images/solutions/ecommerce/block_5_2x.png';
import ImageContainer from '../../components/ImageContainer/ImageContainer';
import BlockTitle from '../../components/BlockTitle/BlockTitle';
import FaqEcommerce from '../../components/FaqEcommerce/FaqEcommerce';

const EcommercePage = (): JSX.Element => {
  const head = {
    title: <Trans>E-commerce</Trans>,
    text: (
      <Trans>
        Octo Browser позволяет безопасно управлять сотнями аккаунтов на всех популярных маркетплейсах, таких как Авито, Amazon, Ebay, Alibaba и Shopify, помогая масштабировать ваш бизнес до любых размеров.
      </Trans>
    ),
    images: [
      {
        image: (
          <ImageContainer
            src={headSrc}
            srcSet={{ _2x: headSrc2x }}
          />
        ),
        locales: {
          exclude: [LanguageMap.ru.key],
        },
      },
      {
        image: (
          <ImageContainer
            src={headRuSrc}
            srcSet={{ _2x: headRuSrc2x }}
          />
        ),
        locales: {
          include: [LanguageMap.ru.key],
        },
      },
    ],
  };

  const descriptions = [
    {
      icon: <Description1 />,
      title: <Trans>Браузерный отпечаток реальных устройств</Trans>,
      text: <Trans>Повышайте эффективность продаж, создавая огромные сети брендов на самых крупных e-commerce платформах, без ограничений на количество аккаунтов.</Trans>,
    },
    {
      icon: <Description2 />,
      title: <Trans>Управление репутацией</Trans>,
      text: <Trans>Оперируйте сотнями аккаунтов для создания положительных отзывов, повышения рейтинга и доверия к вашему бренду с помощью одного удобного инструмента.</Trans>,
    },
    {
      icon: <Description3 />,
      title: <Trans>Автоматизация процессов</Trans>,
      text: <Trans>Создавайте скрипты, автоматизируйте наиболее трудозатратные задачи и осуществляйте безопасный парсинг данных конкурентов.</Trans>,
    },
    {
      icon: <Description4 />,
      title: <Trans>Командная работа</Trans>,
      text: <Trans>Используйте теги для гибкой организации процессов и эффективной командной работы одновременно по многим направлениям.</Trans>,
    },
  ];

  const blocks = {
    items: [
      {
        images: [
          {
            image: (
              <ImageContainer
                src={block1Src}
                srcSet={{ _2x: block1Src2x }}
              />
            ),
          },
        ],
        title: <Trans>Увеличение торговых предложений</Trans>,
        text: <Trans>Увеличьте представительство ваших брендов в различных категориях товаров и услуг без дополнительных вложений.</Trans>,
      },
      {
        images: [
          {
            image: (
              <ImageContainer
                src={block2Src}
                srcSet={{ _2x: block2Src2x }}
              />
            ),
          },
        ],
        title: <Trans>Распределение рисков</Trans>,
        text: <Trans>Разделяйте учётные записи для разных брендов, снижая риски полной потери клиентов при блокировке основного канала продаж и источника постоянного дохода.</Trans>,
      },
      {
        images: [
          {
            image: (
              <ImageContainer
                src={block3Src}
                srcSet={{ _2x: block3Src2x }}
              />
            ),
          },
        ],
        title: <Trans>Улучшение поиска по запросам пользователей</Trans>,
        text: <Trans>Расширьте количество позиций своих товаров в поисковой выдаче и вытесняйте из топа конкурентов.</Trans>,
      },
      {
        images: [
          {
            image: (
              <ImageContainer
                src={block4Src}
                srcSet={{ _2x: block4Src2x }}
              />
            ),
          },
        ],
        title: <Trans>Стимулирование продаж</Trans>,
        text: <Trans>Octo Browser — мощное средство стимулирования продаж и управления репутацией с помощью отзывов.</Trans>,
      },
      {
        images: [
          {
            image: (
              <ImageContainer
                src={block5Src}
                srcSet={{ _2x: block5Src2x }}
              />
            ),
          },
        ],
        title: <Trans>Контроль конкурентной среды</Trans>,
        text: <Trans>Настройте безопасный парсинг данных и получите конкурентное преимущество в вашей категории товаров.</Trans>,
      },
    ],
    title: (
      <BlockTitle
        highlightIsBlock
        highlightText={<Trans>в электронной коммерции и дропшиппинге</Trans>}
        title={<Trans>Возможности Octo Browser</Trans>}
        maxWidthTitle={800}
      />
    ),
  };
  const faq = (
    <FaqEcommerce
      title={
        <BlockTitle
          highlightIsBlock
          highlightText={<Trans>Как масштабировать</Trans>}
          title={<Trans>E-commerce бизнес</Trans>}
          highlightPosition="start"
        />
      }
    />
  );
  return (
    <Layout>
      <AppRating />
      <SolutionLayout
        head={head}
        descriptions={descriptions}
        blocks={blocks}
        faq={faq}
      />
      <DownBlock />
    </Layout>
  );
};

export default EcommercePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["ecommercePage", "translation"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
